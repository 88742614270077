a {
  color: #ffc800;
  text-decoration: underline;
}
a:hover {
  color: #cca000;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  line-height: 1.2;
}

p {
  line-height: 1.75;
}

.btn-primary {
  color: #fff;
  background-color: #ffc800;
  border-color: #ffc800;

  &:hover {
    color: #fff !important;
    background-color: #d9aa00 !important;
    border-color: #cca000 !important;
  }

  &:focus {
    color: #fff;
    background-color: #d9aa00;
    border-color: #cca000;
    box-shadow: 0 0 0 0.25rem rgba(255, 208, 38, 0.5);
  }
}

.btn-xl {
  padding: 1.25rem 2.5rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.125rem;
  font-weight: 700;
}

.page-section {
  padding: 6rem 0;

  h2.section-heading,
  .section-heading.h2 {
    font-size: 3rem;
    margin-top: 0;
    margin-bottom: 4rem;
  }

  h3.section-subheading,
  .section-subheading.h3 {
    font-size: 2.5rem;
    font-weight: 400;
    font-style: italic;
    font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
      sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    margin-bottom: 4rem;
  }
}

section#contact {
  background-color: #212529;
  background-image: url("../public/img/map-image.png");
  background-repeat: no-repeat;
  background-position: center;

  .section-heading {
    color: #fff;
    margin-bottom: 6rem;
  }
}
