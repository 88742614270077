.portfolio-item {
  max-width: 26rem;
  margin-left: auto;
  margin-right: auto;

  .portfolio-link {
    position: relative;
    display: block;
    margin: 0 auto;

    .portfolio-hover {
      display: flex;
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(255, 200, 0, 0.9);
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: opacity ease-in-out 0.25s;

      .portfolio-hover-content {
        font-size: 1.25rem;
        color: white;
      }
    }

    &:hover .portfolio-hover {
      opacity: 1;
    }
  }

  .portfolio-caption {
    padding: 1.5rem;
    text-align: center;
    background-color: #fff;

    .portfolio-caption-heading {
      font-size: 1.5rem;
      font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
        sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 700;
      margin-bottom: 0;
    }

    .portfolio-caption-subheading {
      font-style: italic;
      font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
        sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    }
  }
}
