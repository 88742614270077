.portfolio-modal {
  .modal-dialog {
    margin: 1rem;
    max-width: 100vw;
  }

  .modal-content {
    text-align: center;

    .modal-content {
      h2 {
        font-size: 1.5rem;
        line-height: 1.5rem;
      }

      @media (min-width: 768px) {
        h2 {
          font-size: 3rem;
          line-height: 3rem;
        }
      }

      p {
        margin-bottom: 2rem;
      }

      ul.list-inline {
        margin-bottom: 2rem;
      }

      img {
        margin-bottom: 2rem;
      }
    }
  }
}
