#mainNav {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #212529;

  .navbar-toggler {
    padding: 0.75rem;
    font-size: 0.75rem;
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
      sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    text-transform: uppercase;
    font-weight: 700;
  }

  .navbar-brand {
    color: #ffc800;
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
      sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    letter-spacing: 0.0625em;
    text-transform: uppercase;

    img {
      height: 1.5rem;
    }
  }

  .nav-item {
    display: flex;
    align-items: center;
    .nav-link {
      font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
        sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 0.95rem;
      color: #fff;
      letter-spacing: 0.0625em;
    }

    .nav-link.active,
    .nav-link:hover {
      color: #ffc800;
    }
  }
}

@media (min-width: 992px) {
  #mainNav {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border: none;
    background-color: transparent;
    transition: padding-top 0.3s ease-in-out, padding-bottom 0.3s ease-in-out;

    .navbar-brand {
      font-size: 1.5em;
      transition: font-size 0.3s ease-in-out;

      img {
        height: 2rem;
        transition: height 0.3s ease-in-out;
      }
    }

    .nav-item {
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .navbar-shrink {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    background-color: #212529 !important;

    .navbar-brand {
      font-size: 1.25em !important;

      svg,
      img {
        height: 1.5rem !important;
      }
    }
  }
}
