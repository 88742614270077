.masthead {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 10.5rem;
  padding-bottom: 6rem;
  text-align: center;
  color: #fff;
  background-image: url("../../../public/img/start-photo.png");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;

  .masthead-subheading {
    font-size: 1.5rem;
    font-style: italic;
    line-height: 1.5rem;
    margin-bottom: 25px;
    font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
      sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
}

@media (min-width: 768px) {
  .masthead {
    padding-top: 17rem;
    padding-bottom: 12.5rem;

    .masthead-subheading {
      font-size: 2rem;
      font-style: italic;
      line-height: 2.25rem;
      margin-bottom: 3rem;
    }
  }
}
